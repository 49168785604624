import { Application } from './Application'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FirebaseAppProvider } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

const queryClient = new QueryClient()

export default function ApplicationApp({ job, countries }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProvider config={config.firebaseConfig} name={config.firebaseConfig.projectId}>
      <QueryClientProvider client={queryClient}>
        <Application {...{ job, countries }} />
      </QueryClientProvider>
    </FirebaseAppProvider>
  )
}
