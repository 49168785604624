import { useTranslate } from '/machinery/I18n'

import { Form } from '/features/pageOnly/JobApplication/Form'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './Application.css'

export function Application({ job, countries }) {
  const [step, setStep] = React.useState(1)

  return (
    <ContainerXl>
      <ApplicationSection {...{ job, step, handleStep, countries }} />
    </ContainerXl>
  )

  function handleStep(nextStep) {
    setStep(nextStep)
  }
}

function ApplicationSection({ job, step, handleStep, countries }) {
  return (
    <div className={cx(styles.componentSection, step === 3 && styles.smallGap)}>
      <div className={styles.sectionText}>
        <ContentText {...{ step }} />
      </div>
      <Form layoutClassName={styles.formLayout} {...{ job, step, handleStep, countries }} />
    </div>
  )
}

function ContentText({ step }) {
  const { __ } = useTranslate()

  switch (step) {
    case 1: return <p>{__`application-intro`}</p>
    case 2: return <p>{__`application-final-step`}</p>
    case 3: return <ApplicationSuccess />
    default: return __`application-error`
  }

  function ApplicationSuccess() {
    return (
      <>
        <HeadingSm h='3'>{__`thanks-for-applying`}</HeadingSm>
        <p>{__`application-thank-you`}</p>
      </>
    )
  }
}
